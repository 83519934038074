import React, { useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';

// Images
import Spacer from '../img/spacer_1.gif';
import Apply1 from '../img/appli_1.jpg';
import Apply2 from '../img/appli_2.jpg';
import Apply3 from '../img/appli_3.jpg';
import Apply4 from '../img/appli_4.jpg';
import Apply5 from '../img/appli_5.jpg';

// Components
import JaLayout from '../components/JaLayout';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('ja');

const Home = () => {
  useEffect(() => {
    window.location.href = 'https://kugai3.wixsite.com/j-system';
  }, []);

  return <></>
  const data = useStaticQuery(graphql`
    query {
      news: allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___date }
        filter: { fileAbsolutePath: { glob: "**/src/markdown/news-ja/**.md" } }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              thumbnail {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            id
            fields {
              slug
            }
          }
        }
      }
      home: markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/home-ja.md" }) {
        frontmatter {
          influence {
            content
            title
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            disable
          }
          carousel {
            sliderA {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            sliderB {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            sliderC {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mSliderA {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mSliderB {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mSliderC {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            linkA
            linkB
            linkC
          }
          apply {
            description
            title
            url
            img {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          location {
            address
            email
            fax
            phone
            title
          }
        }
      }
    }
  `);

  const slickSettings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <JaLayout>
      <section id="main_bn">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <Link to={data.home.frontmatter.carousel.linkA}>
                <Img
                  className="d-none d-md-block"
                  fluid={data.home.frontmatter.carousel.sliderA.childImageSharp.fluid}
                />
                <Img
                  className="d-block d-md-none main_rwd"
                  fluid={data.home.frontmatter.carousel.mSliderA.childImageSharp.fluid}
                  alt=""
                />
              </Link>
            </div>
            <div className="carousel-item">
              <Link to={data.home.frontmatter.carousel.linkB}>
                <Img
                  className="d-none d-md-block"
                  fluid={data.home.frontmatter.carousel.sliderB.childImageSharp.fluid}
                />
                <Img
                  className="d-block d-md-none main_rwd"
                  fluid={data.home.frontmatter.carousel.mSliderB.childImageSharp.fluid}
                  alt=""
                />
              </Link>
            </div>
            <div className="carousel-item">
              <Link to={data.home.frontmatter.carousel.linkC}>
                <Img
                  className="d-none d-md-block"
                  fluid={data.home.frontmatter.carousel.sliderC.childImageSharp.fluid}
                />
                <Img
                  className="d-block d-md-none main_rwd"
                  fluid={data.home.frontmatter.carousel.mSliderC.childImageSharp.fluid}
                  alt=""
                />
              </Link>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </section>
      <section id="news" className="m-3 m-md-5">
        <div className="container">
          {data && data.news.edges.length > 0 ? (
            <div className="row">
              <div className="col-12 text-right mb-3 border-bottom">
                <Link to="/ja/news">{LANG.更多新聞}</Link>
              </div>
              {data &&
                data.news.edges.map(
                  ({
                    node: {
                      fields: { slug },
                      frontmatter: { title, thumbnail },
                      excerpt,
                    },
                  }) => {
                    return (
                      <div className="col-lg-4 col-md-4 col-12 mb-3" key={title}>
                        <div className="card ">
                          <Link to={`/ja/news/${slug}`}>
                            {thumbnail && <Img className="card-img-top" fluid={thumbnail.childImageSharp.fluid} />}
                          </Link>
                          <div className="card-body">
                            <h5 className="card-title" style={{ color: 'rgba(108,117,125)' }}>
                              {title}
                            </h5>
                            <p className="card-text text_limit2">{excerpt}</p>
                            <Link to={`/ja/news/${slug}`} className="btn btn-primary">
                              {LANG.閱讀全文}
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          ) : null}
        </div>
      </section>
      {!data.home.frontmatter.influence.disable ? (
        <section id="promo" className="m-4">
          <div className="container ">
            <div className="d-md-flex">
              {data.home.frontmatter.influence.img && (
                <div className="col-12 col-md-7">
                  <Img
                    fluid={data.home.frontmatter.influence.img.childImageSharp.fluid}
                    className="img-thumbnail p-3"
                  />
                </div>
              )}
              <div className="col-12 col-md-5 text-left">
                <p className="h3">
                  <strong>{data.home.frontmatter.influence.title}</strong>
                </p>
                <p>&nbsp;</p>
                <p style={{ whiteSpace: 'pre-line' }}>{data.home.frontmatter.influence.content}</p>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <section id="Related_Product" className="section" style={{ marginBottom: '30px' }}>
        <div className="container">
          <hr className="my-4" />
          <h2 className="text-center">
            <Link to="/ja/product">
              <strong>{LANG.太陽能支架應用方案}</strong>
            </Link>
          </h2>
          <Slider {...slickSettings}>
            {[
              { title: '屋頂型', img: Apply1 },
              { title: '棚架型', img: Apply2 },
              { title: '地面型', img: Apply3 },
              { title: '電力相關', img: Apply5 },
              { title: '特殊型', img: Apply4 },
            ].map((e) => (
              <div className="col p-1">
                <a className="news-card" href={`/ja/product?category=${e.title}`}>
                  <div className="news-cover">
                    <div className="news-cover-bg" style={{ backgroundImage: `url(${e.img}` }}>
                      <img className="w-100" src={Spacer} alt="" />
                    </div>
                  </div>
                  <div className="news-title">{LANG[e.title]}</div>
                  <div className="news-more">Learn more</div>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section id="Location" className="section pb-5" style={{ backgroundColor: '#C8C8C8' }}>
        <div className="container pt-5">
          <h2 className="text-dark text-center py-4">
            <strong>{LANG.聯絡我們}</strong>
          </h2>
          <div className="d-md-flex">
            <div className="col-12 col-md-4 px-0 text-light">
              <div className="p-2 pb-3 m-1  px-4" style={{ backgroundColor: '#1169ac', height: '396px' }}>
                <div className="h5 mt-2">
                  <strong>{LANG.產品技術諮詢}</strong>
                </div>
                <p className="small">{LANG.聯絡表單內文}</p>
                <form
                  id="register_form"
                  role="form"
                  data-toggle="validator"
                  METHOD="POST"
                  name="contact"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  enctype="application/x-www-form-urlencoded"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="form-group">
                    <input
                      id="inputName"
                      name="Name"
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      data-error="Please fill in the Column"
                      required="required"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      id="inputEmail"
                      name="Email"
                      className="form-control"
                      type="email"
                      placeholder="Your eMail"
                      data-error="Mail format error"
                      required="required"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <textarea
                      id="Message"
                      name="Message"
                      type="text"
                      className="form-control"
                      placeholder="Message"
                      data-error="Please fill in the Column"
                      required="required"
                    ></textarea>
                    <div className="help-block with-errors" />
                  </div>
                  <input type="submit" value="Submit" className="btn btn-block btn-dark" />
                </form>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row pb-5">
                <iframe
                  className="border-secondary border m-1"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.249321942486!2d120.22145441545466!3d23.05131928493853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e776618e27e3b%3A0xb9d520284fff2439!2zNzA55Y-w5Y2X5biC5a6J5Y2X5Y2A5a6J5ZKM6Lev5Zub5q61Mzblt7cxNzPomZ8!5e0!3m2!1szh-TW!2stw!4v1611282608584!5m2!1szh-TW!2stw"
                  width="960"
                  height="396"
                  frameBorder="0"
                  style={{ border: '0' }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </JaLayout>
  );
};

export default Home;
